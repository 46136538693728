import * as yup from 'yup';
import { useIntl } from 'react-intl';
import messages from '@utils/messages'; // value: any is necessary because that's what the validateSync function takes
// and we want this to match as closely as possible

export var validateField = function validateField(validator) {
  return function (value, options) {
    try {
      validator.validateSync(value, options);
      return undefined;
    } catch (error) {
      return error.message;
    }
  };
};
export var composeValidators = function composeValidators() {
  for (var _len = arguments.length, validators = new Array(_len), _key = 0; _key < _len; _key++) {
    validators[_key] = arguments[_key];
  }

  return function (value, options) {
    return validators.reduce(function (error, validator) {
      return error || validator(value, options);
    }, undefined);
  };
}; // Check if text has any emojis or special characters. If nothing gets passed it has no emojis.

export var hasEmojis = function hasEmojis(input) {
  if (!input) {
    return false;
  }

  var emojiRegex = new RegExp('\\p{Extended_Pictographic}', 'u');
  return emojiRegex.test(input);
}; // Regex checking if email address if valid.

export var isValidEmail = function isValidEmail(input) {
  if (!input) {
    return true;
  }

  var emailRegex = new RegExp("(^[a-zA-Z0-9]+[a-zA-Z0-9-_.']*@(?:[a-zA-Z0-9-]*[a-zA-Z0-9]+\\.)+[a-zA-Z]{1,}$)");
  return emailRegex.test(input);
}; // Regex for checking if an input is a valid phone number while allowing spaces.

export var isValidPhoneWithSpaces = function isValidPhoneWithSpaces(input) {
  if (!input) {
    return true;
  }

  var phoneSpacesRegex = new RegExp('^[0-9-()+\\- ]*$'); // Valid phone numbers need at least 4 characters if you include 4 digit SMS/texting shortcodes.
  // Some outlying regions have as few as 5 digits: https://stackoverflow.com/questions/14894899/what-is-the-minimum-length-of-a-valid-international-phone-number
  // This may be better handled by a library itself like: https://www.npmjs.com/package/libphonenumber-js
  // Which is based on: https://github.com/google/libphonenumber

  if (input.trim().length < 4) {
    return false;
  }

  return phoneSpacesRegex.test(input);
};
export var isValidChinaMobile = function isValidChinaMobile(input) {
  var allowEmpty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  // If empty strings are allowed and no input is given always return true.
  if (allowEmpty && !input) {
    return true;
  }

  var chinaMobileRegex = new RegExp('^1[3456789]\\d{9}$');
  return chinaMobileRegex.test(input || '');
};
/**
 * For FCs, this can be used as a hook to get validators with pre-translated
 * messages.
 *
 * For class components, you'll need to wrap the component in injectIntl, and
 * pass that injected intl prop to this function, since hooks can't be used in
 * class components (and thus the useIntl hook won't work here).
 *
 */

export var useValidators = function useValidators() {
  var intl = useIntl();
  var email = yup.string().trim().email(intl.formatMessage(messages.EMAIL_MUST_BE_VALID)).max(241, intl.formatMessage(messages.MAX_241_CHARACTERS));
  var requiredEmail = yup.string().trim().test('validateEmail', 'Invalid Email', function (value) {
    return isValidEmail(value);
  }).required(intl.formatMessage({
    id: 'REQUIRED',
    defaultMessage: 'Required'
  })).max(241, intl.formatMessage(messages.MAX_241_CHARACTERS));
  var requiredUserName = yup.string().trim().required(intl.formatMessage(messages.USERNAME_REQUIRED)); // allows a comma-separated list of valid emails

  var ccEmail = yup.string().trim().matches(/^$|(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/, intl.formatMessage(messages.EMAIL_MUST_BE_VALID)).max(241, intl.formatMessage(messages.MAX_241_CHARACTERS));
  var required = yup.string().trim().required(intl.formatMessage({
    id: 'REQUIRED',
    defaultMessage: 'Required'
  }));
  var requiredPhone = yup.string().trim().max(20, intl.formatMessage(messages.MAX_20_CHARACTERS)).matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_PHONE)).required(intl.formatMessage({
    id: 'REQUIRED',
    defaultMessage: 'Required'
  }));
  var requiredPhoneWithSpaces = yup.string().trim().max(20, intl.formatMessage(messages.MAX_20_CHARACTERS)).test('validatePhoneWithSpaces', intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG), function (value) {
    return isValidPhoneWithSpaces(value);
  }).required(intl.formatMessage({
    id: 'REQUIRED',
    defaultMessage: 'Required'
  }));
  var phone = yup.string().max(20, intl.formatMessage(messages.MAX_20_CHARACTERS)).test('validatePhoneWithSpaces', intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG), function (value) {
    return isValidPhoneWithSpaces(value);
  });
  var phoneWithSpaces = yup.string().max(20, intl.formatMessage(messages.MAX_20_CHARACTERS)).test('validatePhoneWithSpaces', intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG), function (value) {
    return isValidPhoneWithSpaces(value);
  });
  var extension = yup.string().max(10, intl.formatMessage(messages.MAX_10_CHARACTERS)).matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_PHONE)).test('atLeastTwoNumbers', intl.formatMessage(messages.MIN_2_NUMBERS), function (v) {
    return (v === null || v === void 0 ? void 0 : v.length) !== 1;
  });
  var extensionWithSpaces = yup.string().max(10, intl.formatMessage(messages.MAX_10_CHARACTERS)).matches(/^[0-9()-\s]*$/, intl.formatMessage(messages.NUMERIC_PHONE_WITH_SPACE)).test('atLeastTwoNumbers', intl.formatMessage(messages.MIN_2_NUMBERS), function (v) {
    return (v === null || v === void 0 ? void 0 : v.length) !== 1;
  });
  var fax = yup.string().max(20, intl.formatMessage(messages.MAX_20_CHARACTERS)).matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_FAX));
  var faxWithSpaces = yup.string().max(20, intl.formatMessage(messages.MAX_20_CHARACTERS)).test('validatePhoneWithSpaces', intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG), function (value) {
    return isValidPhoneWithSpaces(value);
  });
  var mobile = yup.string().max(16, intl.formatMessage(messages.MAX_16_CHARACTERS)).matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_MOBILE));
  var mobileChinaUser = yup.string().test('validChinaMobileNumber', intl.formatMessage(messages.MOBILE_FORMAT_INVALID), function (value) {
    return isValidChinaMobile(value);
  });
  var mobileWithSpaces = yup.string().max(16, intl.formatMessage(messages.MAX_16_CHARACTERS)).test('validatePhoneWithSpaces', intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG), function (value) {
    return isValidPhoneWithSpaces(value);
  });
  var numeric = yup.string().matches(/^[0-9]*$/, intl.formatMessage(messages.NUMBERS_ONLY));
  var floatToTwoDecimalPlaces = yup.string().matches(/^[0-9]+(\.[0-9]{1,2})?$/, intl.formatMessage(messages.PLEASE_ENTER_VALID_AMOUNT)).test('equalToOrGreaterThanOne', intl.formatMessage(messages.PLEASE_ENTER_VALID_AMOUNT), function (n) {
    return Number(n) >= 1;
  });

  var _minLength = function minLength(min) {
    return yup.string().min(min, intl.formatMessage(messages.MIN_N_CHARACTERS, {
      min: min
    }));
  };

  var _maxLength = function maxLength(max) {
    return yup.string().max(max, intl.formatMessage(messages.MAX_N_CHARACTERS, {
      max: max
    }));
  };

  var futureDateRequired = yup.date().max(new Date(Date.UTC(9999, 1)), intl.formatMessage(messages.EXP_YEAR_MUST_BE_4_DIGITS)).min(new Date(), intl.formatMessage(messages.EXP_DATE_CANNOT_BE_IN_PAST)).required(intl.formatMessage({
    id: 'REQUIRED',
    defaultMessage: 'Required'
  }));
  var hasNoEmojis = yup.string().test('emojiTest', intl.formatMessage({
    id: 'EMOJI_NOT_SUPPORTED',
    defaultMessage: ' Emojis are not supported'
  }), function (value) {
    return !hasEmojis(value);
  });
  return {
    email: validateField(email),
    ccEmail: validateField(ccEmail),
    requiredEmail: validateField(requiredEmail),
    requiredUserName: validateField(requiredUserName),
    requiredPhone: validateField(requiredPhone),
    requiredPhoneWithSpaces: validateField(requiredPhoneWithSpaces),
    required: validateField(required),
    phone: validateField(phone),
    phoneWithSpaces: validateField(phoneWithSpaces),
    extension: validateField(extension),
    extensionWithSpaces: validateField(extensionWithSpaces),
    fax: validateField(fax),
    faxWithSpaces: validateField(faxWithSpaces),
    mobile: validateField(mobile),
    mobileWithSpaces: validateField(mobileWithSpaces),
    mobileChinaUser: validateField(mobileChinaUser),
    numeric: validateField(numeric),
    floatToTwoDecimalPlaces: validateField(floatToTwoDecimalPlaces),
    minLength: function minLength(min) {
      return validateField(_minLength(min));
    },
    maxLength: function maxLength(max) {
      return validateField(_maxLength(max));
    },
    futureDateRequired: validateField(futureDateRequired),
    hasNoEmojis: validateField(hasNoEmojis),
    rawYupSchema: {
      email: email,
      ccEmail: ccEmail,
      requiredEmail: requiredEmail,
      requiredUserName: requiredUserName,
      requiredPhone: requiredPhone,
      requiredPhoneWithSpaces: requiredPhoneWithSpaces,
      required: required,
      phone: phone,
      phoneWithSpaces: phoneWithSpaces,
      extension: extension,
      extensionWithSpaces: extensionWithSpaces,
      fax: fax,
      faxWithSpaces: faxWithSpaces,
      mobile: mobile,
      mobileWithSpaces: mobileWithSpaces,
      mobileChinaUser: mobileChinaUser,
      numeric: numeric,
      floatToTwoDecimalPlaces: floatToTwoDecimalPlaces,
      minLength: _minLength,
      maxLength: _maxLength,
      futureDateRequired: futureDateRequired,
      hasNoEmojis: hasNoEmojis
    }
  };
};